import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageItem from "../components/pageItem"
import SEO from "../components/seo"
import Img from "gatsby-image"

const SecondPage = (props) => (
  <Layout pageInfo={{ pageName: "kugel" }}>
    <SEO title="kugel" />
    {/* <h1>Ather</h1> */}
    {/* <p>Welcome to page 2</p> */}
    {/* <Link to="/">Go back to the homepage</Link> */}
    <div id="content" className="pages-placeholder  horizontal-scroll-wrapper" 
    style={{width: `3400vw`,background:"#775700"}}
    >
      {/* <div className="pages"> */}

        <PageItem  ><Img fluid={props.data.kugel01.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel02.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel03.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel04.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel05.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel06.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel07.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel08.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel09.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel10.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel11.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel12.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel13.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel14.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel15.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel16.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel17.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel18.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel19.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel20.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel21.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel22.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel23.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel24.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel25.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel26.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel27.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel28.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel29.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel30.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel31.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel32.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel33.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.kugel34.childImageSharp.fluid} /></PageItem>


{/* <div>item 1</div>
  <div>item 2</div>
  <div>item 3</div>
  <div>item 4</div>
  <div>item 5</div>
  <div>item 6</div>
  <div>item 7</div>
  <div>item 8</div> */}










        {/* <div className="page 01">page01</div>
      <div className="page 02">page02</div> */}
      {/* </div> */}
    </div>
  </Layout>
)

export default SecondPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    kugel01: file(relativePath: { eq: "kugel_1_1.jpg" }) {
      ...fluidImage
    }
    kugel02: file(relativePath: { eq: "kugel_1_2.jpg" }) {
      ...fluidImage
    }
    kugel03: file(relativePath: { eq: "kugel_1_3.jpg" }) {
      ...fluidImage
    }
    kugel04: file(relativePath: { eq: "kugel_1_4.jpg" }) {
      ...fluidImage
    }
    kugel05: file(relativePath: { eq: "kugel_1_5.jpg" }) {
      ...fluidImage
    }
    kugel06: file(relativePath: { eq: "kugel_1_6.jpg" }) {
      ...fluidImage
    }
    kugel07: file(relativePath: { eq: "kugel_1_7.jpg" }) {
      ...fluidImage
    }
    kugel08: file(relativePath: { eq: "kugel_1_8.jpg" }) {
      ...fluidImage
    }
    kugel09: file(relativePath: { eq: "kugel_1_9.jpg" }) {
      ...fluidImage
    }
    kugel10: file(relativePath: { eq: "kugel_1_10.jpg" }) {
      ...fluidImage
    }
    kugel11: file(relativePath: { eq: "kugel_1_11.jpg" }) {
      ...fluidImage
    }
    kugel12: file(relativePath: { eq: "kugel_1_12.jpg" }) {
      ...fluidImage
    }
    kugel13: file(relativePath: { eq: "kugel_1_13.jpg" }) {
      ...fluidImage
    }
    kugel14: file(relativePath: { eq: "kugel_1_14.jpg" }) {
      ...fluidImage
    }
    kugel15: file(relativePath: { eq: "kugel_1_15.jpg" }) {
      ...fluidImage
    }
    kugel16: file(relativePath: { eq: "kugel_1_16.jpg" }) {
      ...fluidImage
    }
    kugel17: file(relativePath: { eq: "kugel_1_17.jpg" }) {
      ...fluidImage
    }
    kugel18: file(relativePath: { eq: "kugel_1_18.jpg" }) {
      ...fluidImage
    }
    kugel19: file(relativePath: { eq: "kugel_1_19.jpg" }) {
      ...fluidImage
    }
    kugel20: file(relativePath: { eq: "kugel_1_20.jpg" }) {
      ...fluidImage
    }
    kugel21: file(relativePath: { eq: "kugel_1_21.jpg" }) {
      ...fluidImage
    }
    kugel22: file(relativePath: { eq: "kugel_1_22.jpg" }) {
      ...fluidImage
    }
    kugel23: file(relativePath: { eq: "kugel_1_23.jpg" }) {
      ...fluidImage
    }
    kugel24: file(relativePath: { eq: "kugel_1_24.jpg" }) {
      ...fluidImage
    }
    kugel25: file(relativePath: { eq: "kugel_1_25.jpg" }) {
      ...fluidImage
    }
    kugel26: file(relativePath: { eq: "kugel_1_26.jpg" }) {
      ...fluidImage
    }
    kugel27: file(relativePath: { eq: "kugel_1_27.jpg" }) {
      ...fluidImage
    }
    kugel28: file(relativePath: { eq: "kugel_1_28.jpg" }) {
      ...fluidImage
    }
    kugel29: file(relativePath: { eq: "kugel_1_29.jpg" }) {
      ...fluidImage
    }
    kugel30: file(relativePath: { eq: "kugel_1_30.jpg" }) {
      ...fluidImage
    }
    kugel31: file(relativePath: { eq: "kugel_1_31.jpg" }) {
      ...fluidImage
    }
    kugel32: file(relativePath: { eq: "kugel_1_32.jpg" }) {
      ...fluidImage
    }
    kugel33: file(relativePath: { eq: "kugel_1_33.jpg" }) {
      ...fluidImage
    }
    kugel34: file(relativePath: { eq: "kugel_1_34.jpg" }) {
      ...fluidImage
    }
  }
`